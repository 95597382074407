import { compose, lifecycle, withState, mapProps, pure } from 'recompose';
import { sleep } from 'lib/utils';

export const withOnloadAnimation = ({ start, end, delay = 0 }) =>
  compose(
    withState('animated', 'setAnimated', false),
    mapProps(({ animated, setAnimated, ...props }) => {
      return {
        ...props,
        animation: {
          run: () => setAnimated(true),
          running: animated,
          start,
          end: animated && end,
          delay,
        },
      };
    }),
    lifecycle({
      async componentDidMount() {
        const { animation } = this.props;
        await sleep(animation.delay);
        animation.run();
      },
    }),
    pure,
  );
