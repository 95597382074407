import React from 'react';

const HandTapIcon = ({ selectedView }) => (
  <svg version="1.1" className={`hand-tap-icon ${selectedView}-view`} viewBox="0 0 600 600">
    <g>
      <g>
        <path d="M173.266,229.5v-22.95c-22.95-19.125-38.25-47.812-38.25-80.325c0-57.375,47.812-105.188,105.188-105.188    c57.375,0,105.188,47.812,105.188,105.188c0,24.862-9.562,49.725-24.863,66.938c7.65,0,13.389,1.913,21.037,3.825    c15.301-21.037,22.951-45.9,22.951-72.675C364.517,55.462,309.054,0,240.204,0S115.891,55.462,115.891,124.312    C115.891,168.3,138.841,206.55,173.266,229.5z" />
        <path d="M326.267,124.312c0-47.812-38.25-86.062-86.063-86.062s-86.062,38.25-86.062,86.062c0,21.038,7.65,40.163,19.125,53.55    v-53.55c0-36.337,30.6-66.938,66.938-66.938s66.938,30.6,66.938,66.938v53.55C318.616,164.475,326.267,145.35,326.267,124.312z" />
        <path d="M469.704,267.75c-11.475,0-21.037,3.825-28.688,9.562l0,0c0-26.775-21.037-47.812-47.812-47.812    c-11.475,0-22.951,3.825-32.514,11.475c-7.648-19.125-24.861-30.6-43.986-30.6c-11.475,0-21.038,3.825-28.688,9.562v-95.625    c0-26.775-21.038-47.812-47.812-47.812s-47.812,21.038-47.812,47.812v170.213c-38.25-34.425-80.325-61.2-107.1-34.425    c-38.25,38.25,42.075,112.837,103.275,225.675c43.988,78.412,105.188,107.1,166.388,107.1    c89.887,0,162.562-72.675,162.562-162.562v-114.75C517.517,288.787,496.479,267.75,469.704,267.75z M498.392,357.638v72.675    c0,74.588-65.025,143.438-143.438,143.438c-72.675,0-114.75-40.162-149.175-95.625c-74.588-126.225-128.138-183.6-107.1-204.638    c21.038-21.037,72.675,24.862,112.837,66.938V124.312c0-15.3,13.388-28.688,28.688-28.688s28.688,13.388,28.688,28.688v200.812    h19.125v-66.938c0-15.3,13.387-28.688,28.688-28.688c15.299,0,28.688,13.388,28.688,28.688V306h19.125v-28.688    c0-15.3,13.387-28.688,28.688-28.688c15.299,0,28.688,13.388,28.688,28.688v47.812h19.125v-9.562    c0-15.3,13.387-28.688,28.688-28.688c15.299,0,28.688,13.388,28.688,28.688V357.638z" />
      </g>
    </g>
  </svg>
);

export default HandTapIcon;
